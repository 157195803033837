<!-- eslint-disable vue/no-v-html -->
<template>
	<b-card-group>
		<b-card>
			<h4 class="mb-4 ">
				{{ translate('replicated_site') }}
				<b-button
					v-b-modal="'replicated-site-modal'"
					variant="gray-dark"
					class="p-0 ml-2 align-bottom">
					<i class="fas fa-exclamation-circle" />
				</b-button>
			</h4>
			<is-loading
				:loading="loading"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')" />
			<div
				v-if="!loading && Object.values(replicatedSiteData).length > 0"
				class="table-responsive">
				<table class="table table-fixed normal-table">
					<tbody>
						<template v-if="showField('replicated_url')">
							<general-setting-field
								:key="'replicated_url'"
								:key-value="'replicated_url'"
								:field-information="checkFieldInformation('replicated_url', replicatedSiteData['replicated_url'])"
								:field-name="translate('replicated_url')"
								:errors="errors.errors['replicated_url']"
								:loading="loading"
								@clearField="clearError('replicated_url')"
								@editField="editReplicatedSite({ value: $event, key : 'replicated_url' })" />
						</template>
						<template v-if="showField('name')">
							<general-setting-field
								:key="'name'"
								:key-value="'name'"
								:field-information="checkFieldInformation('name', replicatedSiteData['name'])"
								:field-name="translate('display_name')"
								:errors="errors.errors['name']"
								:loading="loading"
								:max-char="maxCharsName"
								@clearField="clearError('name')"
								@editField="editReplicatedSite({ value: $event, key : 'name' })" />
						</template>
						<template v-if="showField('use_display_name_leaderboard') && !isAffiliate && replicatedSiteData['user']">
							<general-setting-field
								:key="'use_display_name_leaderboard'"
								:key-value="'use_display_name_leaderboard'"
								:field-information="checkFieldInformation('use_display_name_leaderboard', replicatedSiteData['user']['use_display_name_leaderboard'])"
								:field-name="translate('use_display_name_leaderboard')"
								:errors="errors.errors['user.use_display_name_leaderboard']"
								:loading="loading"
								@clearField="clearError('user.use_display_name_leaderboard')"
								@editField="editReplicatedSite({ value: $event, key : 'use_display_name_leaderboard' })" />
						</template>
						<template v-if="showField('email')">
							<general-setting-field
								v-for="(item, index) in replicatedSiteData['email']"
								:key="`${index}_email`"
								:key-value="`email_${index}`"
								:field-information="checkFieldInformation('email', index)"
								:field-name="translate('email')"
								:errors="errors.errors[`sites.email.${index}.value`]"
								:loading="loading"
								@clearField="clearError(`sites.email.${index}.value`)"
								@editField="editReplicatedSite({ value: $event, key: 'email', id: replicatedSiteData['email'][index].id})" />
						</template>
						<template v-if="showField('mobile')">
							<general-setting-field
								v-for="(item, index) in replicatedSiteData['mobile']"
								:key="`${index}_mobile`"
								key-value="mobile_number"
								:field-information="checkFieldInformation('mobile', index)"
								:field-name="translate('mobile_number')"
								:errors="errors.errors[`sites.mobile.${index}.value`]"
								:loading="loading"
								@clearField="clearError(`sites.mobile.${index}.value`)"
								@editField="editReplicatedSite({ value: $event, key: 'mobile', id: replicatedSiteData['mobile'][index].id})" />
						</template>
						<!--<template v-if="showField('skype')">
							<general-setting-field
								v-for="(item, index) in replicatedSiteData['skype']"
								:key="`${index}_skype`"
								:key-value="`skype_${index}`"
								:field-information="checkFieldInformation('skype', index)"
								:field-name="'skype' | capitalize"
								:errors="errors.errors[`sites.skype.${index}.value`]"
								:loading="loading"
								@clearField="clearError(`sites.skype.${index}.value`)"
								@editField="editReplicatedSite({ value: $event, key: 'skype', id: replicatedSiteData['skype'][index].id })" />
						</template>-->
						<template v-if="showField('tiktok')">
							<general-setting-field
								v-for="(item, index) in replicatedSiteData['tiktok']"
								:key="`${index}_tiktok`"
								:key-value="`tiktok_${index}`"
								:field-information="checkFieldInformation('tiktok', index)"
								:field-name="'tiktok' | capitalize"
								:errors="errors.errors[`sites.tiktok.${index}.value`]"
								:loading="loading"
								@clearField="clearError(`sites.tiktok.${index}.value`)"
								@editField="editReplicatedSite({ value: $event, key: 'tiktok', id: replicatedSiteData['tiktok'][index].id })" />
						</template>
						<template v-if="showField('facebook')">
							<general-setting-field
								v-for="(item, index) in replicatedSiteData['facebook']"
								:key="`${index}_facebook`"
								:key-value="`facebook_${index}`"
								:field-information="checkFieldInformation('facebook', index)"
								:field-name="'facebook' | capitalize"
								:errors="errors.errors[`sites.facebook.${index}.value`]"
								:loading="loading"
								@clearField="clearError(`sites.facebook.${index}.value`)"
								@editField="editReplicatedSite({ value: $event, key: 'facebook', id: replicatedSiteData['facebook'][index].id })" />
						</template>
						<template v-if="showField('twitter')">
							<general-setting-field
								v-for="(item, index) in replicatedSiteData['twitter']"
								:key="`${index}_twitter`"
								:key-value="`twitter_${index}`"
								:field-information="checkFieldInformation('twitter', index)"
								:field-name="'twitter' | capitalize"
								:errors="errors.errors[`sites.twitter.${index}.value`]"
								:loading="loading"
								@clearField="clearError(`sites.twitter.${index}.value`)"
								@editField="editReplicatedSite({ value: $event, key : 'twitter', id: replicatedSiteData['twitter'][index].id })" />
						</template>
						<template v-if="showField('instagram')">
							<general-setting-field
								v-for="(item, index) in replicatedSiteData['instagram']"
								:key="`${index}_instagram`"
								:key-value="`instagram_${index}`"
								:field-information="checkFieldInformation('instagram', index)"
								:field-name="'instagram' | capitalize"
								:errors="errors.errors[`sites.instagram.${index}.value`]"
								:loading="loading"
								@clearField="clearError(`sites.instagram.${index}.value`)"
								@editField="editReplicatedSite({ value: $event, key : 'instagram', id: replicatedSiteData['instagram'][index].id })" />
						</template>
						<template v-if="showField('linkedin')">
							<general-setting-field
								v-for="(item, index) in replicatedSiteData['linkedin']"
								:key="`${index}_linkedin`"
								:key-value="`linkedin_${index}`"
								:field-information="checkFieldInformation('linkedin', index)"
								:field-name="translate('linkedin')"
								:errors="errors.errors[`sites.linkedin.${index}.value`]"
								:loading="loading"
								@clearField="clearError(`sites.linkedin.${index}.value`)"
								@editField="editReplicatedSite({ value: $event, key : 'linkedin', id: replicatedSiteData['linkedin'][index].id })" />
						</template>
						<template v-if="showField('biography')">
							<general-setting-field
								:key="'biography'"
								:key-value="'biography'"
								:field-information="checkFieldInformation('biography', replicatedSiteData['biography'])"
								:field-name="translate('biography')"
								:errors="errors.errors['biography']"
								:loading="loading"
								:max-char="maxChars"
								@clearField="clearError('biography')"
								@editField="editReplicatedSite({ value: $event, key : 'biography' })" />
						</template>
						<template v-if="!isJns() && showField('auto_apply_customer_coupons') && replicatedSiteData['user']">
							<general-setting-field
								:key="'auto_apply_customer_coupons'"
								:key-value="'auto_apply_customer_coupons'"
								:info="translate('auto_apply_customer_coupons_info', { min: COUPONS_MIN_PERCENTAGE, max: COUPONS_MAX_PERCENTAGE })"
								:field-information="checkFieldInformation('auto_apply_customer_coupons', replicatedSiteData['user']['auto_apply_customer_coupons'])"
								:field-name="translate('auto_apply_customer_coupons')"
								:errors="errors.errors['user.auto_apply_customer_coupons']"
								:loading="loading"
								@clearField="clearError('user.auto_apply_customer_coupons')"
								@editField="editReplicatedSite({ value: $event, key : 'auto_apply_customer_coupons' })" />
						</template>
						<tr class="row d-flex mx-0">
							<td class="col-12" />
						</tr>
					</tbody>
				</table>
				<div
					v-if="!isJns()">
					<p class="h5 mb-3 mt-1">
						{{ translate('referral_links') }}
					</p>
					<div class="row no-gutters row-eq-height">
						<div
							v-for="(link, key) in replicatedLinks"
							:key="key"
							:class="['xs', 'sm'].includes(windowWidth) ? '' : 'pr-3'"
							class="col-12 col-md-6 mb-2">
							<replicated-link-card
								:link="link.url"
								:link-name="translate(link.translation.key, translateParams(link.translation.params))"
								:icon-class="link.icon"
								:image-url="link.image" />
						</div>
					</div>
				</div>
			</div>
			<b-modal
				id="replicated-site-modal"
				:title="translate('replicated_site')"
				ok-only>
				<div v-html="translate('replicated_site_modal')" />
			</b-modal>
		</b-card>
	</b-card-group>
</template>
<script>

import { capitalize } from '@/config/Filters';
import WindowSizes from '@/mixins/WindowSizes';
import {
	EDITABLE_FIELDS as editableFields,
	DATE_KEYS as dateKeys,
	BOOLEAN_KEYS as booleanKeys,
	NUMERIC_KEYS as numericKeys,
	EMAIL_KEY as emailKey,
	MAX_CHAR_BIOGRAPHY as maxChars,
	MAX_CHAR_DISPLAY_NAME as maxCharsName,
} from '@/settings/Profile';
import { LANDING_PAGES } from '@/settings/Replicated';
import { COUPONS_MIN_PERCENTAGE, COUPONS_MAX_PERCENTAGE } from '@/settings/Coupons';
import { affiliate, distributor } from '@/settings/Roles';
import {
	Alerts, Products, Profile, Users, Validations,
} from '@/translations';
import ReplicatedSite from '@/util/ReplicatedSite';
import GeneralSettingField from '@/views/Users/components/GeneralSettingsField';
import ReplicatedLinkCard from './components/ReplicatedLinkCard';
import EventBus from '@/util/eventBus';

export default {
	name: 'ReplicatedSite',
	messages: [Profile, Products, Validations, Users, Alerts],
	components: { GeneralSettingField, ReplicatedLinkCard },
	filters: {
		capitalize,
	},
	mixins: [WindowSizes],
	data() {
		return {
			alert: new this.$Alert(),
			booleanKeys,
			numericKeys,
			dateKeys,
			distributor,
			editableFields,
			replicatedSite: new ReplicatedSite(),
			emailKey,
			maxChars,
			maxCharsName,
			COUPONS_MIN_PERCENTAGE,
			COUPONS_MAX_PERCENTAGE,
		};
	},
	computed: {
		response() {
			try {
				const { data } = this.replicatedSite.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		replicatedSiteData() {
			try {
				return this.response.response;
			} catch (error) {
				return {};
			}
		},
		replicatedLinks() {
			try {
				return Object.fromEntries(Object.entries(this.replicatedSiteData.replicated_links).map(([key, link]) => [
					key,
					{ ...LANDING_PAGES[key], url: link.url },
				]));
			} catch (error) {
				return {};
			}
		},
		loading() {
			try {
				return this.replicatedSite.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			try {
				return this.replicatedSite.data.errors;
			} catch (error) {
				return [];
			}
		},
		isAffiliate() {
			return this.$user.details().type === affiliate;
		},
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.replicatedSite.getReplicatedSite(this.$user.details().id);
		},
		editReplicatedSite(payload) {
			let params = {};
			const specialKeys = ['biography', 'name', 'auto_apply_customer_coupons', 'use_display_name_leaderboard'];
			if (!specialKeys.includes(payload.key)) {
				params = { sites: {} };
				params.sites[payload.key] = [];
				params.sites[payload.key].push({ id: payload.id, value: payload.value });
			} else {
				params = { [payload.key]: payload.value };
			}

			this.replicatedSite.updateReplicatedSite(this.$user.details().id, params).then(() => {
				EventBus.$emit('updateAccountSteps');
				this.alert.toast('success', this.translate('replicated_site_updated'));
				this.getData();
			}).catch(() => {
				if (this.errors.errors.use_display_name_leaderboard !== undefined) {
					this.alert.toast('error', this.errors.errors.use_display_name_leaderboard[0]);
				}
				if (this.errors.errors.auto_apply_customer_coupons !== undefined) {
					this.alert.toast('error', this.errors.errors.auto_apply_customer_coupons[0]);
				}
			});
		},
		checkFieldInformation(item, index) {
			const fieldInformation = {};
			if (this.replicatedSiteData[item] === null || typeof this.replicatedSiteData[item] === 'string') {
				fieldInformation.value = this.replicatedSiteData[item];
			} else if (typeof this.replicatedSiteData[item] === 'object') {
				fieldInformation.value = this.replicatedSiteData[item][index].value;
			} else if (this.booleanKeys.includes(item)) {
				fieldInformation.value = !!(this.replicatedSiteData[item] ?? this.replicatedSiteData.user[item]);
			} else if (this.numericKeys.includes(item)) {
				fieldInformation.value = this.replicatedSiteData[item] ?? this.replicatedSiteData.user[item];
			}
			fieldInformation.editable = this.editableFields.includes(item);
			return fieldInformation;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors.clear(field);
			}
		},
		showField(item) {
			return (this.replicatedSiteData[item] || this.editableFields.includes(item));
		},
		translateParams(params) {
			if (params) {
				return Object.fromEntries(Object.entries(params).map(([key, value]) => [key, this.translate(value)]));
			}
			return params;
		},
	},
};
</script>
