<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="card h-100">
		<div class="card-body px-0 py-1">
			<div class="row no-gutters h-100">
				<div class="col h-100 d-flex flex-column">
					<div class="row mx-0 mt-1">
						<div class="col px-3">
							<div
								class="d-flex align-items-center"
								style="height: 1.875em !important;">
								<div
									class="h-100 d-flex align-items-center"
									style="width: 2.25em !important">
									<i
										v-if="!!iconClass && !imageUrl"
										:class="iconClass"
										class="mh-100 icon-color ml-1 mr-2" />
									<img
										v-if="!!imageUrl"
										:src="imageUrl"
										class="mh-100 mr-2">
								</div>
								<div class="font-weight-bold">
									{{ linkName }}
								</div>
							</div>
						</div>
					</div>
					<div class="row mx-0 my-auto">
						<div
							class="col px-3 d-flex align-items-center"
							style="min-height: 2em;">
							<a
								:href="link"
								target="_blank"
								v-html="formattedLink" />
						</div>
					</div>
					<div class="row mx-0 mt-2 mb-2">
						<div class="col px-3">
							<copy-button
								:copy-content="link"
								:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
								icon-class="fas fa-link"
								btn-class="btn-sm btn-success" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CopyButton from '@/components/CopyButton';
import { Dashboard } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import { formatUrlWordBreaks } from '@/util/Urls';

export default {
	name: 'ReplicatedLinkCard',
	messages: [Dashboard],
	components: { CopyButton },
	mixins: [WindowSizes],
	props: {
		link: {
			type: String,
			required: true,
		},
		linkName: {
			type: String,
			default: '',
		},
		imageUrl: {
			type: String,
			default: '',
		},
		// the icon is overridden if an image is specified
		iconClass: {
			type: String,
			default: '',
		},
	},
	computed: {
		formattedLink() {
			return formatUrlWordBreaks(this.link);
		},
	},
};
</script>
<style scoped>
.icon-color {
	color: #c8ced3;
}
</style>
